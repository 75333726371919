import { useEffect, useState } from 'react';
import Datepicker, { type DateType, type DateValueType } from 'react-tailwindcss-datepicker';
import Tooltip from './Tooltip';
import moment from 'moment';

type Props = {
    i18n?: string;
    asSingle?: boolean;
    blankValues?: boolean;
    startDate?: DateType;
    endDate?: DateType;
    showTooltip?: boolean;
    tooltipText?: string;
    isValid?: boolean;
    inputName?: string;
    disabled?: boolean;
    readOnly?: boolean;
    containerClassName?: string;
    inputClassName?: string;
    minDate?: Date;
    onChange?: (name: string, value: DateValueType) => void;
};

const defaultTooltip = 'To type date in input use this format: 09/09/2023 to 09/10/2023';

export const reformatDateRange = (value: DateValueType): DateValueType => {
    if (!value) {
        return value;
    }

    const startDate = reformateDate(value.startDate);
    const endDate = reformateDate(value.endDate);

    return { startDate, endDate };
};

export const reformateDate = (value: DateType): DateType => {
    const localDate: DateType = value ? moment(value).local().format('YYYY-MM-DD') : '';
    if (typeof value === 'string') {
        if (value.length < 12) {
            const strDate = `${localDate}T00:00:00`;
            return new Date(strDate);
        }
    }
    return localDate;
};

export default function DatePicker({
    asSingle = false,
    startDate = null,
    endDate = null,
    showTooltip = false,
    tooltipText = defaultTooltip,
    i18n = 'en-US',
    isValid = true,
    inputName,
    disabled,
    readOnly,
    minDate = undefined,
    containerClassName = '',
    inputClassName = '',
    onChange,
}: Props) {
    const [value, setValue] = useState<DateValueType>(
        reformatDateRange({
            startDate,
            endDate,
        })
    );

    useEffect(() => {
        setValue(reformatDateRange({ startDate, endDate }));
    }, [endDate, startDate]);

    const handleValueChange = (newValue: DateValueType, e?: HTMLInputElement | null) => {
        const value = reformatDateRange(newValue);
        setValue(value);
        if (e?.name && onChange) {
            onChange(e.name, value);
        }
    };

    const placeholder = '--/--/----';
    const separator = 'to';

    const errorClassName = 'border-red-600 bg-secondary-50';

    return (
        <div className={`flex items-center ${containerClassName}`}>
            <Datepicker
                inputClassName={`${inputClassName} relative transition-all duration-300 py-2.5 pl-4 pr-14 w-full border-primary-50 rounded-sm tracking-wide font-light text-sm placeholder-gray-400 focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-blue-500 focus:ring-blue-500/20 ${
                    !isValid ? errorClassName : 'bg-white'
                }`}
                i18n={i18n}
                displayFormat={i18n == 'en-US' ? 'MM/DD/YYYY' : 'DD/MM/YYYY'}
                showShortcuts={true}
                asSingle={asSingle}
                value={value}
                onChange={handleValueChange}
                separator={separator}
                placeholder={asSingle ? placeholder : `${placeholder} ${separator} ${placeholder}`}
                inputName={inputName}
                readOnly={readOnly}
                disabled={disabled}
                useRange={!asSingle}
                minDate={minDate}
            />
            {showTooltip && <Tooltip>{tooltipText}</Tooltip>}
        </div>
    );
}
